import React from "react";

const Sitiosweb = React.lazy(() => import("./pages/Sitios/index"));
const Inicio = React.lazy(() => import("./pages/Principal/index"));
const Contacto = React.lazy(() => import("./pages/Contacto/index"));
const Asesoria = React.lazy(() => import("./pages/Asesoria/index"));
const Contenidos = React.lazy(() => import("./pages/Contenidos/index"));
const PageTerms = React.lazy(() => import("./pages/Terminos/PageTerms"));
const PageError = React.lazy(() => import("./pages/404/PageError"));


const routes = [
  //routes without Layout

   // Landings
  { path: "/", component: Inicio },
  { path: "/contenidos", component: Contenidos },
  { path: "/sitios-web", component: Sitiosweb },
  { path: "/asesoria", component: Asesoria },
  { path: "/contacto", component: Contacto },
  { path: "/terminos", component: PageTerms },
  { path: "/page-error", component: PageError, isWithoutLayout: true },

  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
